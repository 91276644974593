import axios from "axios";

export default {
  async getCMS(context) {
    const response = await axios.get("/api/v1/cms/contents");
    context.commit("SET_CMS", response.data.items);
  },
  async getBanners(context) {
    const response = await axios.get("/api/v1/banner");
    context.commit("SET_BANNERS", response.data.items);
  },
  async inquire(_, payload) {
    await axios.post("/api/v1/inquiry", payload);
  },
  async fetchBlobImage(context, payload) {
    const response = await axios.get(`/api/v1/system/uploads/${payload}`, {
      responseType: "blob",
    });
    context.commit("SET_BLOB_IMAGE", URL.createObjectURL(response.data));
  },
  async fetchProductBlobImage(context, payload) {
    const response = await axios.get(`/api/v1/system/uploads/${payload}`, {
      responseType: "blob",
    });
    context.commit(
      "SET_PRODUCT_BLOB_IMAGE",
      URL.createObjectURL(response.data)
    );
  },
  async order(context, payload) {
    const response = await axios.post("/api/v1/histories/order", payload);
    context.commit("SET_ORDER_DETAIL", response.data);
  },
  async updateOrder(context, payload) {
    const response = await axios.put(
      `/api/v1/histories/order/${payload.id}`,
      payload.data
    );
    context.commit("SET_ORDER_DETAIL", response.data.item);
  },
  async getCMSContactUs(context) {
    const response = await axios.get("/api/v1/cms/contents/cms-contact-us");
    context.commit("SET_CMS_CONTACT_US", response.data.item);
  },
  async getCMSSocialMedia(context) {
    const response = await axios.get("/api/v1/cms/contents/cms-social-media");
    context.commit("SET_CMS_SOCIAL_MEDIA", response.data.item);
  },
  async getCMSAfterSalesService(context) {
    const response = await axios.get("/api/v1/cms/contents/cms-after-sales-service");
    context.commit("SET_CMS_AFTER_SALES_SERVICE", response.data.item);
  },
  async getOtherPayment(context) {
    const response = await axios.get("/api/v1/cms/payment/other-payment");
    context.commit("SET_OTHER_PAYMENT", response.data.item);
  },
};
