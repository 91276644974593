<template>
  <section class="about-us">
    <div class="banner">
      <img
        crossorigin="anonymous"
        :src="`${url}/api/v1/system/uploads/${getBanner?.thumbnail}`"
        alt=""
      />
      <!-- <img :src="blobImage" alt="" /> -->
      <h3>{{ cmsContent.title }}</h3>
    </div>
    <base-container>
      <el-row :gutter="60">
        <el-col :span="12">
          <div class="about" v-html="cmsContent.content"></div>
        </el-col>
        <el-col :span="12">
          <img crossorigin="anonymous" :src="`${url}/api/v1/system/uploads/${cmsContent.thumbnail}`" alt="" />
        </el-col>
      </el-row>
    </base-container>
  </section>
</template>

<script>
import { url } from "@/url";

export default {
  data() {
    return {
      url,
    };
  },
  computed: {
    cms() {
      return this.$store.getters["dashboard/cms"];
    },
    cmsContent() {
      return this.cms.find((item) => item.slug === "cms-about-us");
    },
    banners() {
      return this.$store.getters["dashboard/banners"];
    },
    getBanner() {
      return this.banners.find((item) => item.slug === "about-us");
    },
    blobImage() {
      return this.$store.getters["dashboard/blobImage"];
    },
  },
  // created() {
  //   this.$store.dispatch("dashboard/fetchBlobImage", this.getBanner.thumbnail);
  // },
};
</script>

<style scoped>
.banner {
  position: relative;
}
.banner img {
  height: auto;
  width: 100%;
  /* min-height: 300px; */
  object-fit: cover;
}

h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Avenir";
  font-style: normal;
  font-weight: 850;
  font-size: 70px;
  line-height: 109px;
  color: #ffffff;
}

.about:deep(h4) {
  font-size: 48px;
}
.about:deep(p) {
  font-size: 24px;
}

h4 {
  font-size: 48px;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 76px;
  color: #384967;
  margin-bottom: 1rem;
}

p {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 850;
  font-size: 24px;
  line-height: 37px;
  color: #262f36;
  margin-bottom: 1rem;
}

img {
  width: 100%;
  /* height: 24rem; */
  object-fit: contain;
}
</style>