<template>
  <div class="payment-success">
    <base-container>
      <h3>{{ $t("dashboard.order-confirmed") }}</h3>
      <p>
        {{ $t("dashboard.order-confirmed-message") }}
      </p>
    </base-container>
  </div>
</template>

<script>
import { mounted } from 'vue';
import { ElNotification } from "element-plus";

export default {
  // async mounted() {
  //   //dont know why no token is attached to axios when mounted so add this delay
  //   await new Promise(resolve => setTimeout(resolve, 1500))
  //   if (this.$route.params.id) {
  //     this.$store
  //       .dispatch("dashboard/updateOrder", {
  //         data: {
  //           status: "Paid",
  //         },
  //         id: this.$route.params.id,
  //       })
  //       .then(() => {
  //         ElNotification({
  //           title: "Success",
  //           message: this.$t("form.information-updated"),
  //           type: "success",
  //         });
  //       })
  //       .catch((err) => {
  //         ElNotification({
  //           title: "Error",
  //           message: err.response.data.message,
  //           type: "error",
  //         });
  //       });
  //   }
  // }
}

</script>

<style scoped>
.payment-success {
  height: 55vh;
}

h3 {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 76px;
  text-align: center;
  color: #384967;
  margin-top: 2rem;
}

p {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 850;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  color: #262f36;
  margin: 0 auto;
  margin-top: 2rem;
  width: 37rem;
}
</style>