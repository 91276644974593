export default {
  SET_CMS(state, payload) {
    state.cms = payload;
  },
  SET_BANNERS(state, payload) {
    state.banners = payload;
  },
  SET_BLOB_IMAGE(state, payload) {
    state.blobImage = payload;
  },
  SET_PRODUCT_BLOB_IMAGE(state, payload) {
    state.productBlobImage = payload;
  },
  SET_ORDER_DETAIL(state, payload) {
    state.orderDetail = payload;
  },
  SET_CMS_CONTACT_US(state, payload) {
    state.contactUs = payload;
  },
  SET_CMS_SOCIAL_MEDIA(state, payload) {
    state.socialMedia = payload;
  },
  SET_CMS_AFTER_SALES_SERVICE(state, payload) {
    state.afterSalesService = payload;
  },
  SET_OTHER_PAYMENT(state, payload) {
    state.otherPayment = payload;
  },
};
