<template>
  <section class="after-sales">
    <base-container>
      <el-row justify="center">
        <el-col>
          <div class="card" v-for="item in afterSalesService.meta?.address" :key="item">
            <!-- <h2>{{ $t("dashboard.office-name") }}</h2> -->
            <h2>{{ item.name }}</h2>
            <div class="info">
              <div class="img-wrapper">
                <img src="../assets/location.svg" alt="" />
              </div>
              <p>
                {{ item.address }}
              </p>
            </div>
            <div class="info">
              <div class="img-wrapper">
                <img src="../assets/icons/clock.png" alt="" />
              </div>
              <div v-html="item.openingHour"></div>
            </div>
            <div class="info">
              <div class="img-wrapper">
                <img src="../assets/call.svg" alt="" />
              </div>
              <p>{{ item.contactNumber }}</p>
            </div>
            <div class="info">
              <a
                :href="`https://wa.me/${item.whatsappNumber}`"
                target="_blank"
                v-if="item.whatsappNumber"
              >
                <base-button>{{  $t("dashboard.whatsapp-us") }}</base-button>
              </a>
            </div>
          </div>
        </el-col>
      </el-row>
    </base-container>
  </section>
</template>

<script>
export default {
  computed: {
    afterSalesService() {
      return this.$store.getters["dashboard/afterSalesService"];
    },
  },
  mounted() {
    this.$store.dispatch("dashboard/getCMSAfterSalesService");
  },
};
</script>

<style scoped>
.after-sales {
  display: flex;
  justify-content: center;
}

.el-row {
  width: 55rem;
}

.card {
  padding: 2rem;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.card .info {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.card .info .img-wrapper {
  background: #384967;
  border-radius: 100%;
  width: 2.2rem;
  height: 2.2rem;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.card .info img {
  width: 80%;
}
</style>