<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="carousel" v-if="isDataLoaded">
    <!-- <el-carousel autoplay>
      <el-carousel-item v-for="image in getBanner?.images" :key="image" style="height: auto"
>
        <img
          crossorigin="anonymous"
          :src="`${url}${image}`"
        />

      </el-carousel-item>
    </el-carousel> -->
    <video v-if="getBanner?.videoSource" playsinline autoplay muted loop id="myVideo" ref="video1">
      <source :src="`${url}${getBanner.videoSource}`" type="video/mp4">
    </video>
    <el-carousel v-else indicator-position="inside" arrow="never"  >
      <el-carousel-item v-for="image in getBanner?.images" :key="image">
        <img
          crossorigin="anonymous"
          :src="`${url}${image}`"
        />
      </el-carousel-item>
    </el-carousel>
    <LoginRequiredDialog
      @closeDialog="dialogVisible = false"
      :dialog-visible="dialogVisible"
    />
  </div>
</template>

<script>
import * as tokenData from "@/utils/checkToken";
import { url } from "../../url";
import LoginRequiredDialog from "../LoginRequiredDialog.vue";

export default {
  components: {
    LoginRequiredDialog,
  },
  data() {
    return {
      img: null,
      dialogVisible: false,
      url: url,
    };
  },
  computed: {
    banners() {
      return this.$store.getters["dashboard/banners"];
    },
    getBanner() {
      return this.banners.find((item) => item.slug === "home");
    },
    fetchedBanner() {
      return `http://localhost:3001/api/v1/system/uploads/${this.getBanner.thumbnail}`;
    },
    isDataLoaded() {
      return this.banners.length > 0;
    },
    blobImage() {
      return this.$store.getters["dashboard/blobImage"];
    },
    products() {
      return this.$store.getters["product/products"];
    },
  },
  methods: {
    goToCart(product) {
      if (tokenData.valid) {
        this.$store.commit("product/STORE_CART_ITEMS", {
          id: product.id,
          product: `${url}/api/v1/system/uploads/${product.thumbnail}`,
          retailPrice: product.carSize[0].retailPrice,
          reservationFee: product.carSize[0].reservationFee,
          productColor: product.colorVariant[0].color,
          productName: product.name,
          productSlug: product.slug,
          productSize: product.carSize[0].name,
        });
        this.$router.push(`/cart`);
      } else {
        this.dialogVisible = true;
      }
    },
    selectProduct(product) {
      this.$router.push(`/product/${product.slug}`);
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.img-content {
  width: 100%;
  height: 100%;
  position: relative;
}

img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  aspect-ratio: 12 / 5;
}

.img-content div {
  position: absolute;
  top: 30%;
  left: 4rem;
}

h3,
p {
  font-family: "Avenir";
  font-style: normal;
  color: #f2f3f5;
}

h3 {
  font-weight: 850;
  font-size: 64px;
  line-height: 100px;
}

p {
  font-weight: 350;
  font-size: 32px;
  line-height: 49px;
  max-width: 470px;
  margin: 1rem 0;
}

.el-button {
  border-color: #384967;
}

.carousel .el-button.is-login {
  width: fit-content;
  border: 1px solid #ffffff;
  color: #ffffff;
}

:deep(.el-carousel__container) {
  /* height: 670px; */
  /* height: calc(1903px*5/12) */
  height: calc((96vw)*5/12)
}

:deep(.el-carousel__button) {
  border-radius: 100%;
  width: 0.8rem;
  height: 0.8rem;
  background: #d1d4d7;
}

:deep(.el-carousel__indicators--horizontal) {
  bottom: 2rem;
  left: 4rem;
  transform: translateX(0);
}

#myVideo {
  width: 100%;
}

@media only screen and (max-width: 550px) {
}
</style>