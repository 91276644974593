import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state() {
    return {
      cms: [],
      banners: [],
      blobImage: "",
      productBlobImage: "",
      orderDetail: {},
      contactUs: {},
      socialMedia: {},
      afterSalesService: {},
      otherPayment: true
    };
  },
  getters,
  mutations,
  actions,
};
