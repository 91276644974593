<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="specification" v-if="activeName">
    <base-container>
      <h3>{{ $t("dashboard.specification") }}</h3>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="setTab">
          <el-tab-pane
            v-for="spec of specificationTab"
            :label="spec"
            :name="spec"
            :key="spec"
          >
          <table>
            <tr>
              <th>{{ productDetail.name }} {{  $t("dashboard.specification-type") }}</th>
              <th v-for="size in productDetail.carSize">{{ size.name }}</th>
            </tr>
            <tr v-for="data in tableData">
              <td>{{ data.title }}</td>
              <td v-for="cell in data.value">{{ cell.description }}</td>
            </tr>
          </table>
          </el-tab-pane>
        </el-tabs>
    </base-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      selectedTab: "",
      tableData: [],
      specificationTab: []
    };
  },
  computed: {
    carSize() {
      return this.$store.getters["product/carSize"];
    },
    productDetail() {
      return this.$store.getters["product/productDetail"];
    },
    selectedProductDetails() {
      return this.$store.getters["product/selectedProductDetails"];
    },
    filterCarSize() {
      return this.productDetail.carSize.find(
        (item) => item.name === this.selectedProductDetails.size
      );
    },
    isPropertyValid() {
      return "specification" in this.filterCarSize;
    },
    productSpecifications() {
      return this.$store.getters["product/productSpecifications"];
    },
  },
  methods: {
    setTab(pane) {
      if (pane?.paneName) {
        this.selectedTab = pane.paneName;
      } else {
        this.selectedTab = pane;
      }
      const filterTable = this.productSpecifications.find(
        (item) => item.name === this.selectedTab && item.brandSlug === this.productDetail.slug
      );

      if (!filterTable) {
        this.tableData = [
          {
            label: "No data",
            content: "No data",
          },
        ];
        return;
      }
      this.tableData = filterTable.types;
    },
  },
  mounted() {
    this.specificationTab = this.productSpecifications.filter((productSpec) => {
      return productSpec.brandSlug == this.productDetail.slug
    }).map((productSpec) => productSpec.name);
    this.activeName = this.specificationTab[0];
    this.setTab(this.activeName)
  },
};
</script>

<style scoped>
.specification {
  background: #fff;
}

.specification .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h3 {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #566069;
  margin-bottom: 2.5rem;
}

:deep(.el-tabs__nav.is-top) {
  width: 100%;
  /* justify-content: center; */
}

:deep(.el-tabs__active-bar.is-top) {
  background: #384967;
}

:deep(.el-tabs__item.is-top) {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 28px;
  color: #c9cdd4;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  width: 100%;
}

:deep(.el-tabs__item.is-top.is-active) {
  color: #384967;
}

:deep(.el-table th.el-table__cell) {
  background: #f2f3f5;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 850;
  font-size: 14px;
  line-height: 22px;
  color: #333f48;
}

:deep(.el-table__body .el-table__row .cell) {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;
  color: #566069;
}

th {
  background: #F2F3F5;
  padding: 12px 16px;
  color: #333F48;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
  text-align: start;
}

td {
  padding: 12px 16px;
  color: #262F36;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 22px; /* 157.143% */
}

table, th, td {
  border: 1px solid #E5E6EB;
  border-collapse: collapse;
}
</style>