<template>
  <footer>
    <base-container>
      <el-row justify="space-between">
        <el-col :md="24" :lg="4" class="flex">
          <img class="logo" src="../../assets/logo.png" alt="" />
          <div class="social-media-row">
            <a :href="socialMedia?.meta?.socialMedia?.facebook" target="_blank"><img src="../../assets/icons/facebook.svg" alt="" /></a>
            <a :href="socialMedia?.meta?.socialMedia?.instagram" class="icon-margin" target="_blank"><img src="../../assets/icons/instagram.svg" alt="" /></a>
          </div>
        </el-col>
        <el-col :md="24" :lg="6">
          <p>{{ $t("footer.company") }}</p>
          <div class="list">
            <p @click="$router.push('/home')">{{ $t("menu.home") }}</p>
          </div>
          <div class="list">
            <p @click="$router.push('/about-us')">{{ $t("menu.about-us") }}</p>
          </div>
          <div class="list">
            <p @click="$router.push('/contact-us')">
              {{ $t("menu.contact-us") }}
            </p>
          </div>
          <div class="list">
            <p @click="$router.push('/terms-and-conditions')">
              {{ $t("menu.terms-conditions") }}
            </p>
          </div>
          <div class="list">
            <p @click="$router.push('/privacy-policy')">
              {{ $t("menu.privacy-policy") }}
            </p>
          </div>
        </el-col>
        <el-col :md="24" :lg="5">
          <p>{{ $t("footer.service") }}</p>
          <div class="list">
            <p @click="$router.push('/product')">{{ $t("menu.product") }}</p>
          </div>
          <div class="list">
            <p @click="$router.push('/test-drive-request')">
              {{ $t("menu.test-drive-request") }}
            </p>
          </div>
          <div class="list">
            <p @click="$router.push('/cart')">{{ $t("menu.shopping-cart") }}</p>
          </div>
          <div class="list">
            <p @click="$router.push('/after-sales')">{{ $t("menu.after-sales") }}</p>
          </div>
        </el-col>
        <el-col :md="24" :lg="7">
          <p>{{ $t("footer.office") }}</p>
          <div class="list icons">
            <img src="../../assets/location.svg" alt="" />
            <p>
              {{ contactUs?.address }}
            </p>
          </div>
          <div class="list icons">
            <img src="../../assets/sms.svg" alt="" />
            <p>
              {{ contactUs?.email }}
            </p>
          </div>
          <div class="list icons">
            <img src="../../assets/call.svg" alt="" />
            <p>
              {{ contactUs?.phone }}
            </p>
          </div>
        </el-col>
      </el-row>
    </base-container>
    <hr class="line" />
    <div class="copyright">
      {{ $t("dashboard.footer-copyright", { year: year }) }}
    </div>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      year: ""
    };
  },
  computed: {
    contactUs() {
      return this.$store.getters["dashboard/contactUs"];
    },
    socialMedia() {
      return this.$store.getters["dashboard/socialMedia"];
    },
  },
  methods: {

  },
  mounted() {
    this.$store.dispatch("dashboard/getCMSContactUs");
    this.$store.dispatch("dashboard/getCMSSocialMedia");

    let today = new Date();
    this.year = today.getFullYear()
  },
};
</script>

<style scoped>
footer {
  background: #262f36;
}

img.logo {
  /* width: 100%; */
  width: 12rem;
}

p {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  color: #a6acb1;
  margin-bottom: 1rem;
}

.list {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.list p {
  font-weight: 850;
  font-size: 20px;
  line-height: 31px;
  color: #f2f3f5;
  margin-bottom: 0;
  text-transform: none;
  cursor: pointer;
}

.list.icons p {
  font-size: 16px;
  display: inline-block;
  cursor: default;
}

.list.icons img {
  width: 1.5rem;
  margin-right: 1rem;
}

.flex {
  display: flex;
  flex-direction: column;
}

.social-media-row {
  margin-top: auto;
}

.icon-margin {
  margin-left: 24px;
}

.line {
  margin-left: 5%;
  margin-right: 5%;
}

.copyright {
  color: #F2F3F5;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  margin-top: 24px;
  padding-bottom: 48px;
}

@media only screen and (max-width: 1199px) {
  img.logo {
    margin: 1rem auto 2rem auto;
    display: block;
  }
}
</style>