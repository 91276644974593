import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state() {
    return {
      products: [],
      testDriveRequest: [],
      productDetail: {},
      selectedProductDetails: {},
      cart: "",
      cartItems: [],
      productSpecifications: [],
    };
  },
  getters,
  mutations,
  actions,
};
