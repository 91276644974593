<template>
  <div class="feature-carousel">
    <el-carousel
      trigger="click"
      @change="currentImage"
    >
      <el-carousel-item
        ref="myImage"
        :name="item.thumbnail"
        v-for="item in feature.images"
        :key="item"
      >
        <img
          crossorigin="anonymous"
          :src="`${url}/api/v1/system/uploads/${item.thumbnail}`"
          alt=""
        />
      </el-carousel-item>
    </el-carousel>
    <p>{{ activeImg ? activeImg.description : "" }}</p>
  </div>
</template>

<script>
import { url } from "@/url";

export default {
  props: ["feature"],
  data() {
    return {
      url,
      activeImg: null,
    };
  },
  methods: {
    currentImage(i) {
      this.activeImg = this.feature.images[i]
    },
  },
  mounted() {
    this.currentImage(0);
  },
};
</script>

<style scoped>
img {
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
  display: flex;
  margin: auto;
}

:deep(.el-carousel.el-carousel--horizontal.el-carousel--card) {
  margin: 0 auto;
  width: 80%;
  margin-top: 1rem;
}

p {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #697187;
  margin-top: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .feature-carousel {
    padding-left: 20%;
    padding-right: 20%;
  }
}
</style>