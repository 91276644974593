<template>
  <section class="product-detail" v-if="loadData">
    <product-info></product-info>
    <Feature />
    <Specification />
  </section>
</template>

<script>
import Feature from '@/components/product-detail/Feature.vue';
import ProductInfo from '@/components/product-detail/ProductInfo.vue';
import Specification from '@/components/product-detail/Specification.vue';
import loading from '@/utils/loading';

export default {
  mixins: [loading],
  components: {
    ProductInfo,
    Feature,
    Specification
  },
  data() {
    return {
      loadData: false
    };
  },
  computed: {
    productDetail() {
      return this.$store.getters['product/productDetail'];
    }
  },
  created() {
    this.openLoading();
    this.$store
      .dispatch('product/getProductDetail', this.$route.params.slug)
      .then(() => {
        if (this.productDetail?.status == 'active') {
          this.loadData = true;
        }
        this.closeLoading();
      });
  },
  watch: {
    '$route.params': function (val, oldVal) {
      //
      window.location.reload()
    }
  }
};
</script>
