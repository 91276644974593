import '@/axios'
import router from '@/route.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createApp } from 'vue'
import App from './app.vue'
import i18n from './i18n'
import store from './store/store'

import BaseButton from '@/ui/BaseButton.vue'
import BaseContainer from '@/ui/BaseContainer.vue'
import BaseInput from '@/ui/BaseInput.vue'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

//filter
app.config.globalProperties.$filters = {
    thoushandSeparator(value) {
      return value.toLocaleString()
    }
  }

app.use(ElementPlus)
app.use(router)
app.use(store)
app.use(i18n)

app.component('base-container', BaseContainer)
app.component('base-input', BaseInput)
app.component('base-button', BaseButton)
app.component('VueDatePicker', VueDatePicker);

app.mount('#app')
