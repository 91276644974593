export default {
  cms(state) {
    return state.cms;
  },
  banners(state) {
    return state.banners;
  },
  blobImage(state) {
    return state.blobImage;
  },
  productBlobImage(state) {
    return state.productBlobImage;
  },
  orderDetail(state) {
    return state.orderDetail;
  },
  contactUs(state) {
    return state.contactUs;
  },
  socialMedia(state) {
    return state.socialMedia;
  },
  afterSalesService(state) {
    return state.afterSalesService;
  },
  otherPayment(state) {
    return state.otherPayment;
  },
};
