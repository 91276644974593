<template>
  <theHeader />
  <main>
    <a
      :href="`https://wa.me/${whatsAppNumber}`"
      target="_blank"
      class="whats-app-content"
      v-if="whatsAppNumber"
    >
      <img src="./assets/whatsapp.png" alt="" />
    </a>
    <router-view></router-view>
  </main>
  <!-- <router-view v-if="loadData"></router-view> -->
  <TheFooter />
</template>

<script>
import TheFooter from "@/components/common/TheFooter";
import TheHeader from "@/components/common/TheHeader";
import * as tokenData from "@/utils/checkToken";

export default {
  components: { TheHeader, TheFooter },
  data() {
    return {
      loadData: false,
    };
  },
  watch: {
    $i18n: {
      deep: true,
      async handler() {
        this.getData();
      },
    },
  },
  computed: {
    cms() {
      return this.$store.getters["dashboard/cms"];
    },
    socialMedia() {
      return this.$store.getters["dashboard/socialMedia"];
    },
    whatsAppNumber() {
      // const content = this.cms.find((item) => item.slug === "cms-contact-us");
      const phoneNumber = this.socialMedia?.meta?.socialMedia?.whatsapp?.replace("+", "");
      return phoneNumber?.replaceAll(" ", "");
    },
  },
  methods: {
    async getData() {
      await tokenData.checkAccessToken(false);

      this.$store.dispatch("product/getProducts");
      this.$store.dispatch("dashboard/getCMS");
      this.$store.dispatch("dashboard/getBanners").then(() => {
        this.loadData = true;
      });
      this.$store.dispatch("product/getProductSpecifications");
      this.$store.dispatch("dashboard/getOtherPayment");
    },
  },
  async created() {
    this.getData();
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir";
  /* color: #262f36; */
}
body {
  background: #f2f3f5;
}

main {
  position: relative;
}

main .whats-app-content {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  /* border: 4px solid #384967; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

main .whats-app-content img {
  width: 70%;
  height: 70%;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/avenir.otf") format("opentype");
}


@media only screen and (max-width: 700px) {
  main .whats-app-content {
    /* display: none; */
  }
}
</style>
